(function () {
  $('.hero-slider').slick({
    arrows: false,
    dots: true
  })

  if ($('.hero-slide').length < 2) {
    $('.hero-slider .slick-dots').hide()
  }

  $('.testimonial-slider').slick({
    arrows: true,
    dots: false
  })
})()