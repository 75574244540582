(function () {

  const $header = document.querySelector('.page-header');
  const $menu = document.querySelector('.main-nav');
  const $hamburger = document.querySelector('.burger');
  let isMenuOpened = false;
  $hamburger.addEventListener('click', function () {
    this.classList.toggle('opened')
    $menu.classList.toggle('opened');
    $header.classList.toggle('menu-opened');
    isMenuOpened = !isMenuOpened;
    if (isMenuOpened) {
      bodyScrollLock.disableBodyScroll($menu);
    } else {
      bodyScrollLock.clearAllBodyScrollLocks();
    }
  });

  let navLinks = document.querySelectorAll('.main-nav a');
  navLinks.forEach(link => {
    link.addEventListener('click', function () {
      if (window.innerWidth < 768) {
        $menu.classList.remove('opened');
        $header.classList.remove('menu-opened');
        isMenuOpened = false;
        bodyScrollLock.clearAllBodyScrollLocks();
      }
    })
  })

  window.addEventListener('resize', debounce(function () {
    if (isMenuOpened && window.innerWidth >= 768) {
      bodyScrollLock.clearAllBodyScrollLocks();
    }
  }))

  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
})();
